<template>
<Transition name="slide-fade" appear>
  <div class="px-2 sm:px-6 lg:px-4 text-t3 ">
    <button id="vv" type="button" class="hidden" data-bs-toggle="modal" data-bs-target="#modallo">
        </button>
      <!-- Question Display Tabs -->
			<h2 class="text-2xl font-bold sm:text-3xl">
				Tasks
			</h2>
      <div v-if="scopeCheck('view_question')" class="shadow-t3 bg-white md:pr-6 mt-4 rounded">
        <div class=" space-x-2 flex"></div>
        <ul class="nav nav-tabs flex justify-start flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tab" role="tablist">
            <li v-for="(task,index) in sortedTask() " :key="task.id" class="nav-item" role="presentation">
                <button @click="changeTab(task.id)" :href="'#a'+task.id" :id="task.id" :value="task.id " class="nav-link block leading-tight text-base border-x-0 border-t-0 border-b-2 border-transparent px-3 py-4 hover:border-transparent hover:bg-gray-100 focus:border-transparent" data-bs-toggle="pill" :data-bs-target="'#a'+task.id" role="tab" :aria-controls="'tabs-'+task.id"
                aria-selected="true" :class="{ active : index === 0 }">{{task.name}}</button>
            </li>
            <div class="ml-auto flex items-center">
              
                      <div class="flex justify-end w-full px-6 gap-4 items-center">
                      <div class="mx-4 my-2">
                        <label for="rowscount" class="inline-block mx-2 text-xs 2xl:text-base ">Number of records:</label>
                        <select @change="refreshPage()" v-model="numberOfPages" id="rowscount" class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"> 
                              <option value="12">12</option>
                              <option value="24">24</option>
                              <option value="50">64</option>
                              <option value="100">108</option>
                        </select>
                      </div>
                      <button @click="firstPage()" v-if="page>1" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
                        <div class="flex">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                          </svg>
                        </div>
                      </button>
                      <button @click="previousPage()" v-if="page>1" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                      </button>
                      <p class="text-t3 font-medium text-xs 2xl:text-base" v-if="pageCount">Page {{page}} of {{pageCount}}</p>
                      <button @click="nextPage()" v-if="isNext" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                      </button>

                      <button @click="lastPage()" v-if="isNext" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                        </svg>
                      </button>
                      <button v-if="!filterOn" @click="filterOn = true"
                      class="flex flex-col rounded-full  items-center font-medium transform  text-center p-2 text-t3  hover:text-t3-teritiary focus:rotate-180">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                      </svg>
                  </button>
                  <button v-if="filterOn" @click="cancelFilter()"
                      class="flex flex-col rounded-full p-2 items-center font-medium transform rotate-180 text-center text-t3  hover:text-t3-teritiary focus:rotate-0">

                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2"
                        stroke="currentColor" class="w-6 h-6  ">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                      </svg>
                    </button>  

                    </div>
                    <button class="text-lg h-6 w-6 ml-4 inline-block text-center bg-t3Light text-t3 border-t3 border-solid border hover:border-t3-secondary font-medium leading-tight uppercase rounded hover:bg-t3-secondary hover:text-white focus:bg-t3 focus:shadow-lg focus:text-white focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" v-if="scopeCheck('create_question') && !addingQuestion" @click="addingQuestion = true">+</button>
                    <p class="text-t3-cancel cursor-pointer"  v-if="addingQuestion"  @click="addingQuestion = false">Cancel</p>
            </div>
            </ul>
            <div v-if="filterOn" class="pb-4">
              <label for="filterT" class=" m-2 text-gray-700 text-md  font-semibold ">Filters: </label>
              <select name="filterT" @change="updateQuestions" v-model="filtering"
              class="p-1 form-control w-64 form-control text-md font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
              <option :value="null" >Show All</option>
              <option v-for="license in licenses" :key="license.id" :value="license.id">{{license.name}}</option>
              <option value="deleted" class="text-t3-cancel">Archived</option>
              </select> 
            </div>

      </div>
                  <div v-if="scopeCheck('create_question') && addingQuestion" class=" bg-white md:p-6 mt-4 rounded">
                  <div class="flex justify-between items-center w-full mb-3 mt-5">
                    <div class="w-full">

                      <PulseLoader :loading="loading" color="#0E2D5B" size="7px"></PulseLoader> 
                    <form v-if="!loading" ref="addImageForm ">
                            <!-- PreDisplay -->
                        <div class="w-full flex items-center  ">
                          <div class="xl:flex justify-center items-center w-full">
                          <img v-if="sortedTask().find(t =>  t.id === currentTab ).type ==='img'" id='output-image' class="w-32 h-32 mr-4 border-t3-secondary border my-4 object-cover rounded-xl" alt="">

                              <!-- Input Fields -->

                         <div class="w-full xl:w-4/6">
                          <audio v-if="sortedTask().find(t =>  t.id === currentTab ).type ==='audio'" class="m-auto w-full" id="output-image" controls><source type="audio/mp3"></audio>
                          <input v-if="sortedTask().find(t =>  t.id === currentTab ).type === 'txt'" v-model="question.text" @input="debCheck()" type="text" class="form-control block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                            placeholder="What is today's question" />
                          <textarea v-if="sortedTask().find(t =>  t.id === currentTab ).type !== 'txt'" v-model="question.description" class="form-control mb-2 block w-full p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                            :placeholder="'Describe the attached '+sortedTask().find(t =>  t.id === currentTab ).type"></textarea>
                          <FileInput id="inputfield" ref="fileupload" :accept="acceptFile" v-model="question.content" v-if="sortedTask().find(t =>  t.id === currentTab ).type === 'img' || sortedTask().find(t =>  t.id === currentTab ).type === 'audio' || sortedTask().find(t =>  t.id === currentTab ).type ==='pdf'" @change="previewFiles"  class="form-control block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                         </div>
                        </div>
                        </div>
                        <div v-if="licenses.length > 0" class="w-full flex gap-8 mt-4 justify-between shrink-0 flex-wrap">
                          <p class="text-gray-500">Suitable For: </p>
                          <div class="w-full  flex flex-col items-center h-64 mx-auto">
                          <div class="w-full px-4">
                              <div class="flex flex-col items-center relative">
                                  <div class="w-full  svelte-1l8159u">
                                      <div  class="my-2 p-1 flex border border-gray-200 bg-white rounded svelte-1l8159u">
                                          <div class="flex flex-auto flex-wrap">
                                              
                                              <div v-for="(license, index) in question.license_type" :key="index" class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                                                  <div class="text-xs font-normal leading-none max-w-full flex-initial">{{licenses.find(l => l.id === license).name}} </div>
                                                  <div class="flex flex-auto flex-row-reverse">
                                                      <div>
                                                          <svg @click="licChange(license)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-t3-secondary rounded-full w-4 h-4 ml-2">
                                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                                          </svg>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="flex-1">
                                                  <input placeholder="" class="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800">
                                              </div>
                                          </div>

                                      </div>
                                  </div>
                                  <div class="absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
                                      <div  v-for="license in licenses" :key="license.id" class="flex flex-col w-full" @click="licChange(license.id)">
                                          <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-t3-body hover:text-t3-secondary">
                                              <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                                  <div class="w-full items-center flex">
                                                      <div class="mx-2 leading-6  ">{{license.name}} </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                        </div>
                        <div v-if="licenses.length === 0" class="w-full flex  my-4 justify-center ">
                          <div class="p-6 text-center">
                            <p class="text-t3-mutedText text-md ">It appears your customer licence types have not yet been configured. 
                                <span v-if="!scopeCheck('view_settings')"> <br>
                                  Please ask responsible personnel to configure them.
                                </span> 
                                <span v-if="scopeCheck('view_settings')"> <br>
                                  Please configure them through the Organisations and Discounts Tab on the <router-link :to="{name:'Settings'}" class="text-t3-secondary">Settings Page</router-link> 
                                </span>
                            </p>
                          </div>
                        </div>
                        <div v-if="licenses.length > 0" class="w-full flex md:ml-auto md:full">
                            <button type="submit" @click="submitQuestion" class="my-4 ml-auto font-medium border border-t3 text-t3 bg-white px-4 py-2  rounded-md hover:text-white hover:bg-t3-secondary">Add to list</button>
                          </div>
                    </form>

                    </div>
                  </div>
                        
                  <!-- Question Upload Tabs -->

                  </div>
      <div v-if="scopeCheck('view_question')" class="shadow-t3 bg-white p-6 md:px-6 mt-4 rounded"> 


            <!-- Tab Content -->
            <div class="tab-content" id="tabs-tabContent">
            <div v-for="(task,index) in sortedTask() " :key="index" class="tab-pane fade" :class="{ 'active show': index === 0 }" :id="'a'+task.id" role="tabpanel" aria-labelledby="tabs-text-tab">
                <div>     
                <ul>
                    <li class="text-xl font-semibold text-center text-t3 my-64" v-if="questions.length === 0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>It appears you have encountered an error!! It appears there are no questions here yet.</p>
                     </li>
                      <li class=" border-b p-3 md:flex border-t3-body" v-for="question in questionFilter('txt')" :key="question.id">
                        <span v-if="question.type === 'txt'">{{question.text}}</span>
                        <div class="ml-auto flex">
                        <p v-if="scopeCheck('delete_question') && filtering  !== 'deleted'" @click="editQuestion(question)" class="cursor-pointer  mr-3  text-t3-secondary" >Update</p>
                        <p v-if="scopeCheck('delete_question') && filtering !== 'deleted'" @click="deleter(question.id)" class="cursor-pointer  text-t3-cancel" >Archive</p>
                          <p v-if="scopeCheck('delete_question') && filtering === 'deleted'" @click="restorer(question.id)" class="cursor-pointer text-t3-teritiary" >Restore</p>
                        </div>
                    </li>
                    <li class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        <div class=" border-b p-3 border-t3-body" v-for="question in questionFilter('img')" :key="question.id">
                          <a :href="question.content" target="_blank">
                            <img class=" w-1/2 2xl:w-full object-cover mx-auto rounded hover:drop-shadow-xl hover:scale-150 mb-4" v-if="question.type == 'img'" :src="question.content" :alt="question.description"/>
                          </a>
                            <p v-if="scopeCheck('delete_question') && filtering  !== 'deleted'" @click="editQuestion(question)" class="cursor-pointer text-center text-t3-secondary" >Update</p>
                            <p v-if="scopeCheck('delete_question') && filtering  !== 'deleted'" @click="deleter(question.id)" class="cursor-pointer text-center text-t3-cancel" >Archive</p>
                            <p v-if="scopeCheck('delete_question') && filtering === 'deleted'" @click="restorer(question.id)" class="cursor-pointer text-center text-t3-teritiary" >Restore</p>
                            </div>
                    </li>
                    <li >
                        <div class="md:flex text-center md:text-left w-full justify-items-center content-center text-lg text-t3 border-b p-3 border-t3-body" v-for="question in questionFilter('audio')" :key="question.id">
                            <audio :src="question.content" controls><source type="audio/mp3"></audio>
                            <p class="ml-auto mr-8">{{question.description}}</p>
                            <div class="ml-auto flex">
                              <p v-if="scopeCheck('delete_question') && filtering  !== 'deleted'" @click="editQuestion(question)" class=" cursor-pointer mr-3 text-t3-secondary" >Update</p>
                              <p v-if="scopeCheck('delete_question') && filtering  !== 'deleted'" @click="deleter(question.id)" class=" cursor-pointer text-t3-cancel" >Archive</p>
                              <p v-if="scopeCheck('delete_question') && filtering === 'deleted'"  @click="restorer(question.id)" class="cursor-pointer text-t3-teritiary" >Restore</p>
                            </div>
                            
                            </div>
                    </li>
                    <li>
                        <div class=" border-b p-3 border-t3-body" v-for="question in questionFilter('pdf')" :key="question.id">
                            <a :href="question.content" download>Download {{question.description}}</a>
                        </div>
                    </li>
                    
                </ul>
                </div>
            </div>

        </div> 
        
        </div>

                    <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="modallo" tabindex="-1" aria-labelledby="exampleModalCenterTitle" aria-modal="true" role="dialog">
                <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                    <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                    <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                        <h5 class="text-2xl leading-normal text-t3 font " id="exampleModalScrollableLabel">Editing Question</h5>
                    </div>
                    <div class="modal-body text-center relative p-4">
                      <div v-if="licenses.length > 0" class="w-full mt-4 justify-between shrink-0 flex-wrap">
                      <p class="text-gray-500">Suitable For: </p>
                      
                      <div class="w-full  flex flex-col items-center h-64 mx-auto">
                          <div class="w-full px-4">
                              <div class="flex flex-col items-center relative">
                                  <div class="w-full  svelte-1l8159u">
                                      <div  class="my-2 p-1 flex border border-gray-200 bg-white rounded svelte-1l8159u">
                                          <div class="flex flex-auto flex-wrap">
                                              
                                              <div v-for="(license, index) in editQ.license_type" :key="index" class="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                                                  <div class="text-xs font-normal leading-none max-w-full flex-initial">{{licenses.find(l => l.id === license).name}} </div>
                                                  <div class="flex flex-auto flex-row-reverse">
                                                      <div>
                                                          <svg @click="editChange(license)" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-t3-secondary rounded-full w-4 h-4 ml-2">
                                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                                          </svg>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="flex-1">
                                                  <input placeholder="" class="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800">
                                              </div>
                                          </div>

                                      </div>
                                  </div>
                                  <div class="absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
                                      <div  v-for="license in licenses" :key="license.id" class="flex flex-col w-full" @click="editChange(license.id)">
                                          <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-t3-body hover:text-t3-secondary">
                                              <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                                  <div class="w-full items-center flex">
                                                      <div class="mx-2 leading-6  ">{{license.name}} </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- <div  v-for="license in licenses" :key="license.id">
                            <input type="checkbox" :checked="editQ.license_type.includes(license.id)"  @change="editChange($event,license.id)" name="" id="">
                            <label class="form-check-label inline-block text-gray-500 ml-2" for="flexCheckDefault">{{license.name}}</label>
                        </div> -->
                        </div>
                    </div>
                            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                <button type="button" id="reqClose"
                                class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                                data-bs-dismiss="modal">
                                Close
                                </button>
                                <button type="button"
                                @click="updateQuestion()" class="inline-block px-6 py-2.5 bg-t3-light text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3 hover:shadow-lg focus:bg-t3-night focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                  Update
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>

  </div>
</Transition>

</template>
<style>
    .top-100 {top: 100%}
    .bottom-100 {bottom: 100%}
    .max-h-select {
        max-height: 300px;
    }
</style>
<script>
  import FileInput from '@/components/FileInput.vue';
import { mapActions, mapState } from 'vuex';
import QuestionsServices from "../services/QuestionsServices";
  // import SamplesServices from "../services/SamplesServices"
  import ReportServices from '@/services/ReportServices';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { watchEffect } from 'vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
// import { watchEffect } from 'vue'

export default{


  data(){
    
    return{
      editQ:{
        license_type:[]
      },
      recorder:null,
      currentTab:1,
      questionTab: "txt",
      currentFile:"",
      addingQuestion:false,
      filterTask:null,
      loading:false,
      showHidden:false,
      filterOn:false,
      question: {
        text: null,
        type: 'img',
        description:"",
        content: {},
        for_task: "",
        license_type:[]
      },
      page:1,
			numberOfPages:24,
      filtering:null,
      recordingNow:false
    }
  },

  components: {
      FileInput,
      PulseLoader
    },
    methods:{
    ...mapActions( {
				getquestions: 'Questions/getQuestionsPage',
        createQuestion: 'Questions/createQuestion',
        getTasks: 'Assessments/getAllTasks',
        getSettings: 'Settings/getAllSettings',
        getTypes: 'Settings/getAllCustomerTypes'
			}),
    
      filterHiddens(type){
          return this.hiddens.filter(q => q.type === type)
      },
      editQuestion(question){
        this.editQ = question
                document.getElementById("vv").click()
            },
      updateQuestion(){
        QuestionsServices.updateQuestion(this.editQ.id, {license_type:this.editQ.license_type})
        this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
          this.$forceUpdate()
          document.getElementById("reqClose").click()
      },
      debCheck: _.debounce(function(){
              }, 500),
      questionTask(name){
            return this.questions.filter(q => q.for_task === name )
        },
        nextPage(){
				this.page++
				this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})

			},
      updateQuestions(){
        this.getquestions({id:1,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
      },
      lastPage(){
				this.page=this.pageCount
				this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
			},
			firstPage(){
				this.page=1
				this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
			},
			previousPage(){
				this.page--
				this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
			},
			refreshPage(){
        this.page = 1
				this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
			},

        questionFilter(type){

            return this.questions.filter(q => q.type === type)
        },
        cancelFilter(){
          this.filterOn=false
          this.filtering=null
          this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
        },
      scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}	,
    submitQuestion(e) {
      e.preventDefault();
      this.question.for_task = this.currentTab
      if(this.question.for_task !== ""){
        this.question.type = this.sortedTask().find(t =>  t.id === this.currentTab ).type
        if(this.question.type !== "txt"){
        const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
        if (Array.isArray(val)) {
        val.forEach(v => fd.append(key, v))
        } else {
        fd.append(key, val)
        }
        return fd
        }, new FormData())
        this.loading=true
        this.createQuestion(getFormData(this.question)).then(()=>{
          Swal.fire({
          icon:"success",
          title:"Success!",
          text:"Question Sent.",
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,})
          this.question.text =null
          this.question.description =null
          this.question.content =null
          this.question.for_task =""
          this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
          this.loading=false
          this.$forceUpdate()
          
        }).catch((error)=>{
          let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
          this.loading=false
          this.$forceUpdate()
        })
        
        }
        else{
          
          this.question.content=null;
          if(this.question.text){
            this.loading=true
          this.createQuestion(this.question).then(()=>{
          Swal.fire({
          icon:"success",
          title:"Success!",
          text:"Question Sent. Text",
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,})
          this.question.text =null
          this.question.for_task =""
          this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
          this.loading=false
          this.$forceUpdate()
        }).catch((error)=>{
          let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
          this.loading=false
        })
          }
          else{
        Swal.fire({
        icon:"warning",
        title:"Nu-uh",
        text:"Please fill in the field before sending!",
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,})
          }
          this.loading=false
      }
      }
      else{
        Swal.fire({
        icon:"warning",
        title:"Yikes",
        text:"Please select a task before sending!",
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,})
        this.loading=false
      }

    },

    previewFiles(event) {
       var reader = new FileReader();
        reader.onload = function()
        {
          var output = document.getElementById('output-image');
          output.setAttribute("src",URL.createObjectURL(event.target.files[0]))
          document.getElementById('output-image').load();
        }
        reader.readAsDataURL(event.target.files[0]);
        
        this.currentFile=event.target.value;
       },
    changeTab(task){

      this.currentTab=task
      this.page=1
      this.getquestions({id:1,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
      document.getElementById("inputfield").setAttribute('modelvalue', null)
      this.$refs.addImageForm.reset()
      this.$refs.fileupload.value = null;
      var output = document.getElementById('output-image');
      output.setAttribute("src",null)
      this.question.text =null
      this.question.content =null
      this.question.description =null
      this.question.for_task =""
      

    },
    deleter(id){

        Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to remove this question?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                            QuestionsServices.deleteQuestion(id).then(()=>{
                                      Swal.fire({
                                        icon:"success",
                                        title:"Success!",
                                        text:"The question was successfully deleted",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                      })
                                      this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
                                      this.$forceUpdate()
                                    })
                            }
                        })

    },
    restorer(id){

        Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to recover this question?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                            QuestionsServices.restoreQuestion(id).then(()=>{
                                      Swal.fire({
                                        icon:"success",
                                        title:"Success!",
                                        text:"The question was successfully recovered",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                      })
                                      this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
                                      this.$forceUpdate()
                                    })
                            }
                        })

    },
    sort_by_id() {
				return function (elem1, elem2) {
				if (elem1.id < elem2.id) {
					return -1;
				} else if (elem1.id > elem2.id) {
					return 1;
				} else {
					return 0;
				}
				}},
			sortedTask(){
				return this.tasks.sort(this.sort_by_id())
			},
      licChange(id){
        if(this.question.license_type.includes(id))
        {
          this.question.license_type.splice(this.question.license_type.indexOf(id),1)
          
        }
        else{
          this.question.license_type.push(id)
        }
      },
      editChange(id){
        
        if(this.editQ.license_type.includes(id))
        {this.editQ.license_type.splice(this.editQ.license_type.indexOf(id),1)}
        else{
          this.editQ.license_type.push(id)
          
        }
        
      }
  },
      computed: {
			...mapState({
        tasks: state => state.Assessments.tasks,
        isNext: state => state.Questions.next,
        counts: state => state.Questions.count,
				questions: state => state.Questions.questions,
        hiddens: state => state.Questions.hiddens,
        licenses: state => state.Settings.customer_types
			}),
      pageCount(){
				if(this.counts/this.numberOfPages > 1){
					return Math.ceil(this.counts/this.numberOfPages)
				}
				else{
					return null
				}
			},
      acceptFile(){
        let extension = ""
        switch(this.currentTab){
          case "img":
            extension = "image/*";
            break;
          case "audio":
            extension = "audio/*";
            break;
          case "pdf":
            extension = ".pdf"
            break;
        }
        return extension
      }
		},

		created() {
      this.getquestions({id:this.page,number:this.numberOfPages,task:this.currentTab,type:this.filtering})
      this.getTypes().then(()=>{
          this.question.license_type=this.licenses.map(l=>{
            return l.id
          })
        })
      watchEffect(()=>{
        
        this.getTasks()

      })
		},
}
</script>
<style scoped>
  .nav-tabs .nav-link.active {
    color: #212D63;
    border-color: #00ADEE;
}

.nav-tabs .nav-link {
    color: #9ca4af;
}
.input-group > .form-control {
    width: 75%;
}
</style>
